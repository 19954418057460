<template>
	<Drawer :drawer="drawer" :dialogWidth="dialogWidth" v-on:close="$emit('close', true)">
		<template v-slot:title>
			<v-layout class="align-items-center">
				<div>Create Terms & Conditions</div>
				<v-flex class="text-right" v-if="false">
					<v-btn depressed x-small fab class="mx-2" v-on:click="$emit('close', true)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:body>
			<v-form
				ref="termsConditionsForm"
				v-model="formValid"
				lazy-validation
				v-on:submit.stop.prevent="updateOrCreate()"
			>
				<v-row class="py-0">
					<v-col md="2" class="py-0" v-if="!isSingle">
						<label for="" class="btx-label mt-3 required">Select Module</label>
					</v-col>
					<v-col md="10" class="py-0" v-if="!isSingle">
						<AutoCompleteInput
							hide-details
							:items="moduleList"
							:disabled="pageLoading"
							:loading="pageLoading"
							id="Module"
							placeholder="Module"
							v-model="create_data.module"
							:class="{
								required: !create_data.module,
							}"
							:rules="[vrules.required(create_data.module, 'module')]"
						>
						</AutoCompleteInput>
					</v-col>
					<v-col md="2" class="py-0">
						<label for="" class="btx-label mt-3 required">Title</label>
					</v-col>
					<v-col md="10" class="py-0">
						<TextInput
							hide-details
							:disabled="pageLoading"
							placeholder="Title"
							:loading="pageLoading"
							:id="`title`"
							v-model="create_data.title"
							:class="{
								required: !create_data.title,
							}"
							:rules="[vrules.required(create_data.title, 'Title')]"
						></TextInput>
					</v-col>
					<v-col md="2" class="py-0">
						<label for="" class="btx-label mt-3">Description</label>
					</v-col>
					<v-col md="10" class="py-0">
						<TextAreaInput
							:rows="5"
							hide-details
							placeholder="Description"
							v-model="create_data.terms_and_conditions"
							:disabled="pageLoading"
							:loading="pageLoading"
							class="mb-3"
						></TextAreaInput>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				tile
				depressed
				:disabled="pageLoading"
				class="mx-2"
				color="white"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
			<v-btn
				tile
				depressed
				:disabled="pageLoading"
				:loading="pageLoading"
				class="mx-2 white--text"
				color="blue darken-4 white--text"
				v-on:click="updateOrCreate()"
			>
				Save
			</v-btn>
		</template>
	</Drawer>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import Drawer from "@/view/components/Drawer";
import ApiService from "@/core/services/api.service";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, cloneDeep } from "lodash";

export default {
	name: "SelectTermsAndConditions",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		drawerType: {
			type: String,
			default: "create",
		},
		isSingle: {
			type: Boolean,
			default: false,
		},
		module: {
			type: String,
			default: null,
		},
		defaultData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		drawer(param) {
			if (param) {
				this.init();
			}
		},
	},
	data: () => {
		return {
			pageLoading: false,
			formValid: true,
			create_data: {
				id: null,
				uuid: null,
				module: null,
				title: null,
				terms_and_conditions: null,
			},
			moduleList: ["quotation", "purchase-order"],
		};
	},
	methods: {
		updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.termsConditionsForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.termsConditionsForm.validate()) {
				return false;
			}
			_this.pageLoading = true;
			ApiService.setHeader();
			if (this.isSingle) {
				this.create_data["module"] = this.module;
			}
			ApiService.post(`termconditions`, { terms_and_conditions: [this.create_data], is_single: 1 })
				.then(({ data }) => {
					_this.$store.dispatch(SET_LOCAL_DB).then(() => {
						_this.$emit("close", data);
						_this.$emit("success", data);
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		init() {
			if (this.drawer_type === "create") {
				this.create_data = {
					id: null,
					uuid: null,
					module: null,
					title: null,
					terms_and_conditions: null,
				};
			} else {
				const defaultData = cloneDeep(this.defaultData);
				this.create_data = {
					id: defaultData.id ? defaultData.id : null,
					uuid: defaultData.uuid ? defaultData.uuid : null,
					module: defaultData.module ? defaultData.module : null,
					title: defaultData.title ? defaultData.title : null,
					terms_and_conditions: defaultData.terms_and_conditions
						? defaultData.terms_and_conditions
						: null,
				};
			}
		},
	},
	components: {
		Drawer,
		TextInput,
		TextAreaInput,
		AutoCompleteInput,
	},
	mounted() {
		this.init();
	},
	computed: {
		...mapGetters(["errors", "termsAndConditions"]),
		drawer_type() {
			return this.drawerType;
		},
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 800;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 90);
		},
	},
};
</script>
