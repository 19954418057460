<template>
	<v-row justify="center">
		<v-navigation-drawer
			class="mail-template-drawer"
			content-class="common-drawer"
			v-model="drawer"
			app
			stateless
			right
			temporary
			:width="dialogWidth"
		>
			<v-card tile elevation="0">
				<v-card-title
					class="py-1 grey lighten-4 blue--text text--darken-4 text-uppercase border-bottom sticky-on-top"
				>
					<div class="d-flex w-100">
						<div class="h4 m-0 my-auto">
							<slot name="title"></slot>
						</div>
						<v-spacer></v-spacer>
						<slot name="action-top"></slot>
						<v-btn depressed x-small fab class="mx-2" v-on:click="$emit('close', true)">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</v-card-title>
				<v-card-text v-if="$slots.body" :class="{ 'px-8 py-4': !dense, 'px-0 py-0': dense }">
					<slot name="body"></slot>
				</v-card-text>
				<v-card-actions class="fixed-on-bottom grey lighten-4">
					<v-spacer></v-spacer>
					<slot name="action"></slot>
				</v-card-actions>
			</v-card>
		</v-navigation-drawer>
	</v-row>
</template>

<style scoped>
.v-dialog__content {
	align-items: start;
}
</style>

<script>
export default {
	props: {
		drawer: {
			type: Boolean,
			required: true,
			default: false,
		},
		dense: {
			type: Boolean,
			required: false,
		},
		dialogWidth: {
			type: Number,
			default: 768,
		},
	},
};
</script>
