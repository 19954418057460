<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper company-detail-drawer">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-6-bold">Company Details</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						v-on:click="updateCompanyDetails"
						class="blue darken-4"
						>Save</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Company Name</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							placeholder="Company Name"
							v-model="company_details.company_name"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Employee Count</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							type="number"
							placeholder="Employee count"
							v-model.number="company_details.employee_count"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Phone Number</label>
						</div>
						<PhoneInput
							required
							hide-details
							hide-top-margin
							:disabled="pageLoading"
							:loading="pageLoading"
							id="phone-number"
							placeholder="Phone Number"
							v-model="company_details.phone_number"
							:validation-field="{
								url_type: 'company_details',
								filter_type: 'company_details',
								field: 'phone_number',
							}"
							:parent-id="company_details.id"
							:current-id="company_details.id"
						></PhoneInput>
						<v-text-field
							v-if="false"
							append-icon="mdi-phone"
							depressed
							hide-details
							outlined
							v-model="company_details.phone_number"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Email</label>
						</div>
						<div class="width-70">
							<EmailInput
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								:loading="pageLoading"
								id="company-email"
								placeholder="Email"
								v-model="company_details.email_address"
								:rules="[vrules.required(company_details.email_address, 'Email')]"
								:class="{
									required: !company_details.email_address,
								}"
								:validation-field="{
									url_type: 'company_details',
									filter_type: 'company_details',
									field: 'email',
								}"
								:parent-id="company_details.id"
								:current-id="company_details.id"
								append-icon="mdi-email"
							></EmailInput>
						</div>
						<v-text-field
							v-if="false"
							append-icon="mdi-email"
							depressed
							hide-details
							outlined
							v-model="company_details.email_address"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Website</label>
						</div>
						<v-text-field
							append-icon="mdi-web"
							depressed
							hide-details
							outlined
							placeholder="website"
							v-model="company_details.website_url"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Postal code</label>
						</div>
						<v-text-field
							v-mask="`######`"
							depressed
							hide-details
							outlined
							placeholder="postal code"
							v-model="company_details.zip_code"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Address line 1</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							placeholder="address line 1"
							v-model="company_details.street_1"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Address line 2</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							placeholder="address line 2"
							v-model="company_details.street_2"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Unit Number</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							placeholder="Unit Number"
							v-model="company_details.unit_number"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 width-30">
							<label class="fw-500">Country</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							placeholder="Country"
							v-model="company_details.country"
						></v-text-field>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { cloneDeep } from "lodash";
import EmailInput from "@/view/components/EmailInput";
import PhoneInput from "@/view/components/PhoneInput";
import { UpdateCompanyDetails } from "@/core/lib/setting.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "UpdateCompanyDetail",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		details: {
			type: Object,
			default: new Object(),
		},
	},
	data() {
		return {
			company_name: null,
			pageLoading: false,
			company_details: {
				company_name: null,
				employee_count: 0,
				phone_number: null,
				email_address: null,
				street_1: null,
				street_2: null,
				website_url: null,
				zip_code: null,
				unit_number: null,
				country: null,
			},
			members: null,
			memberList: [
				{ value: 1, name: "Brain Halligan", avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg" },
				{ value: 2, name: "Halligan" },
				{ value: 3, name: "Brain", avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg" },
				{ value: 4, name: "Brain4", avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg" },
				{ value: 5, name: "Brain5" },
			],
		};
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(param) {
				this.company_details = cloneDeep(param);
			},
		},
	},
	methods: {
		updateCompanyDetails() {
			UpdateCompanyDetails(this.company_details)
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company details has been updated." },
					]);
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		EmailInput,
		PhoneInput,
	},
};
</script>
<style scoped></style>
