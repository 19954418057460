<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Category</div>
			<v-spacer></v-spacer>
		</v-layout>
		<div>
			<!-- <ModuleSettings type="service" title="service"></ModuleSettings> -->
			<v-row>
				<v-col md="2" class="category-list pt-0 border-right">
					<v-tabs
						vertical
						v-model="settingTab"
						background-color="red"
						slider-color="blue darken-4"
						active-class="blue lighten-5 blue--text text--darken-4"
					>
						<v-tab href="#designation">Designation</v-tab>
						<v-tab href="#supplier_category">Supplier Category</v-tab>
						<v-tab href="#unit"> Unit</v-tab>
						<v-tab href="#manufacturer">Manufacturer</v-tab>
						<v-tab href="#product_category">Product Category</v-tab>
						<v-tab href="#bank_names">Bank Names</v-tab>
						<!-- <v-tab href="#services">Services</v-tab> -->
					</v-tabs>
				</v-col>
				<v-col md="10" class="pt-0">
					<v-tabs-items v-model="settingTab">
						<v-tab-item value="designation">
							<DesignationSetting />
						</v-tab-item>
						<v-tab-item value="supplier_category">
							<CategorySetting
								title="Supplier Category"
								apiUrl="category"
								getData="categories"
							></CategorySetting>
						</v-tab-item>
						<v-tab-item value="unit">
							<UnitSetting title="Unit"></UnitSetting>
						</v-tab-item>
						<v-tab-item value="manufacturer">
							<ManufacturerSetting></ManufacturerSetting>
						</v-tab-item>
						<v-tab-item value="product_category">
							<ProductCategorySetting></ProductCategorySetting>
						</v-tab-item>
						<v-tab-item value="bank_names">
							<BankSetting></BankSetting>
						</v-tab-item>
						<!-- <v-tab-item value="services">
							<CategorySetting title="Services"></CategorySetting>
						</v-tab-item> -->
					</v-tabs-items>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import CategorySetting from "@/view/components/CategorySetting.vue";
import DesignationSetting from "@/view/components/DesignationSetting.vue";
import UnitSetting from "@/view/components/UnitSetting.vue";
import ManufacturerSetting from "@/view/components/ManufacturerSetting.vue";
import BankSetting from "@/view/components/BankSetting.vue";
import ProductCategorySetting from "@/view/components/ProductCategorySetting.vue";
export default {
	name: "Service",
	data() {
		return {
			pageLoading: null,
		};
	},
	components: {
		CategorySetting,
		DesignationSetting,
		UnitSetting,
		ManufacturerSetting,
		BankSetting,
		ProductCategorySetting,
	},
	created() {
		const _this = this;
		if (_this.$route.query && _this.$route.query.subCat) {
			_this.settingTab = _this.$route.query.subCat;
		}
	},
	computed: {
		settingTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.subCat = val;
				if (val != this.settingTab) {
					this.$router.push({ query });
				}
			},
			get() {
				return this.$route.query.subCat || "designation";
			},
		},
	},
};
</script>
<style scoped>
.category-list {
	width: 1rem !important;
}
</style>
