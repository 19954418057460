<template>
	<div>
		<div :class="`Unit-tab-details border`">
			<v-layout
				class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
			>
				<div class="my-auto">Unit</div>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-4 white--text mr-4" tile depressed @click="addCategory()">
					<v-icon left>mdi-plus</v-icon>
					Add
				</v-btn>
				<v-btn
					color="blue darken-4 white--text"
					tile
					depressed
					@click="saveCategory()"
					:disabled="subCategories.length < 1"
				>
					<!-- <v-icon left>mdi-plus</v-icon> -->
					Save
				</v-btn>
			</v-layout>
			<div class="px-6 py-4">
				<v-form v-if="!pageLoading" ref="categoryForm" lazy-validation>
					<v-row
						v-for="(row, index) in subCategories"
						@click="makeEditAble(index)"
						:key="index"
						class="mb-3 list-view"
					>
						<!-- {{ row.isEditable }} -->
						<v-col class="py-0" v-if="!row.unit || row.isEditable">
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:id="`unit-${index}`"
								:ref="`unit-${index}`"
								placeholder="Unit"
								v-model="row.unit"
								:rules="[vrules.required(row.unit, 'unit Name')]"
								:class="{
									required: !row.unit,
								}"
								v-on:keydown.tab="addCategoryOnTabPress(index)"
								@keydown.delete="deleteCategoryOnBackspacePress(index)"
							></TextInput>
						</v-col>
						<v-col class="py-0 my-auto" v-else @click="toggleToEditableState(index)">
							<p class="px-3 mb-0">{{ row.unit }}</p>
						</v-col>
						<v-col md="1" class="py-0">
							<v-btn
								:disabled="subCategories.length === 1"
								class="white--text cursor-pointer"
								depressed
								color="red darken-4"
								tile
								@click="removeCategory(index, row)"
								min-width="30"
								max-width="30"
								width="30"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
				<div v-else class="mx-20 my-40 text-center">
					<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
import ApiService from "@/core/services/api.service";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { cloneDeep, map } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
export default {
	name: "unit-setting",
	props: {
		title: {
			type: String,
			default: null,
		},
		isDisabled: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			pageLoading: false,
			previouslyActiveInputFieldIndex: -1,
			subCategories: [],
		};
	},
	components: {
		TextInput,
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	methods: {
		addCategory(/* index */) {
			// if (this.subCategories.length) {
			// 	this.$nextTick(() => {
			// 		const element = this.$refs[`unit-${index}`];
			// 		const area = element[0].$el.querySelector("input");
			// 		area.focus();
			// 	});
			// }
			// this.subCategories.push({
			// 	id: null,
			// 	unit: null,
			// 	isEditable: true,
			// });

			this.subCategories.forEach((row) => {
				row.isEditable = false;
			});
			this.subCategories.push({
				unit: null,
				id: null,
				uuid: null,
				isEditable: true,
			});
			if (this.subCategories.length) {
				this.$nextTick(() => {
					const focus_element = `unit-${this.subCategories.length - 1}`;
					document.getElementById(focus_element).focus();
				});
			}
		},
		removeCategory(index) {
			this.subCategories.splice(index, 1);
		},
		makeEditAble(index) {
			this.subCategories.forEach((row) => {
				row.isEditable = false;
			});
			this.subCategories[index].isEditable = true;
		},
		saveCategory() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.categoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.categoryForm.validate()) {
				return false;
			}
			this.pageLoading = true;
			const newData = _this.subCategories.filter((ele) => {
				if (!ele.unit) {
					return;
				}
				return ele;
			});
			ApiService.setHeader();
			ApiService.post(`measurement-unit`, { units: newData })
				.then(({ data }) => {
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.$emit("close-dialog", true);
					_this.$emit("success", data);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
			this.subCategories.forEach((row) => {
				row.isEditable = false;
			});
		},
		addCategoryOnTabPress(index) {
			if (this.subCategories.length - 1 == index) this.addCategory();
			if (!(this.subCategories.length - 1 == index)) {
				this.subCategories.forEach((row) => {
					row.isEditable = false;
				});
				this.subCategories[index + 1].isEditable = true;
				setTimeout(() => {
					const focus_element = `unit-${index + 1}`;
					document.getElementById(focus_element).focus();
				}, 1);
			}
		},
		deleteCategoryOnBackspacePress(index) {
			if (!this.subCategories[index].unit && this.subCategories.length > 1) {
				this.removeCategory(index);
				this.subCategories[index - 1].isEditable = true;
				if (this.subCategories.length) {
					this.$nextTick(() => {
						const element = this.$refs[`unit-${index - 1}`];
						const area = element[0].$el.querySelector("input");
						area.focus();
					});
				}
			}
		},
		toggleToEditableState(index) {
			this.subCategories[index].isEditable = true;
			if (this.previouslyActiveInputFieldIndex !== -1)
				this.subCategories[this.previouslyActiveInputFieldIndex].isEditable = false;
			this.previouslyActiveInputFieldIndex = index;
		},
	},
	mounted() {
		let categoryData = cloneDeep(this.localDB("measurement_units", []));
		this.subCategories = map(categoryData, (row) => {
			row.isEditable = false;
			return row;
		});
		if (this.subCategories.length < 1) {
			this.subCategories.push({
				unit: null,
				id: null,
				uuid: null,
				isEditable: true,
			});
			if (this.subCategories.length) {
				this.$nextTick(() => {
					const focus_element = `unit-${this.subCategories.length - 1}`;
					document.getElementById(focus_element).focus();
				});
			}
		}
	},
};
</script>
<style scoped>
.list-view:not(:last-child) {
	border-bottom: 0.1rem solid #eee;
	padding-bottom: 1rem;
}
.cursor-pointer:hover {
	cursor: pointer;
}
</style>
