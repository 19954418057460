<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper company-detail-drawer">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-6-bold">Change Social Information</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						v-on:click="updateCompanyDetails"
						class="blue darken-4"
						>Save</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<p class="mt-0">
						You can change the access URL the way you want. Existing URL will stop working eventually.
					</p>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Whatsapp</label>
						</div>
						<PhoneInput
							hide-details
							hide-top-margin
							:disabled="pageLoading"
							:loading="pageLoading"
							id="whatsapp-number"
							placeholder="Whatsapp Number"
							v-model="company_details.whatsapp_number"
							:validation-field="{
								url_type: 'company_details',
								filter_type: 'company_details',
								field: 'whatsapp_number',
							}"
							:parent-id="company_details.id"
							:current-id="company_details.id"
						></PhoneInput>
						<v-text-field
							v-if="false"
							depressed
							hide-details
							outlined
							v-model="company_details.whatsapp_number"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Facebook</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							v-model="company_details.facebook_url"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Instagram</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							v-model="company_details.instagram_url"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Twitter</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							v-model="company_details.twitter_url"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Google +</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							v-model="company_details.google_plus_url"
						></v-text-field>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { cloneDeep } from "lodash";
import PhoneInput from "@/view/components/PhoneInput";
import { UpdateCompanyDetails } from "@/core/lib/setting.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "AccessUrl",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		details: {
			type: Object,
			default: new Object(),
		},
	},
	data() {
		return {
			url: "abx-xyz",
			pageLoading: false,
			company_details: {
				facebook_url: null,
				google_plus_url: null,
				instagram_url: null,
				twitter_url: null,
				whatsapp_number: null,
			},
		};
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(param) {
				this.company_details = cloneDeep(param);
			},
		},
	},
	methods: {
		updateCompanyDetails() {
			UpdateCompanyDetails(this.company_details)
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company details has been updated." },
					]);
					this.$emit("success", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		PhoneInput,
	},
};
</script>
<style scoped></style>
