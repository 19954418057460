<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Terms & Conditions Setting</div>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<div :class="`countrylist-tab-details border`">
			<v-layout
				class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
			>
				<div class="mx-3">
					<v-menu offset-y right>
						<template v-slot:activator="{ attrs, on }">
							<div class="my-auto" v-bind="attrs" v-on="on">
								{{ filter_text }}
								<v-icon color="blue darken-4">mdi-menu-down</v-icon>
							</div>
						</template>
						<v-list class="py-0">
							<v-list-item class="py-1" @click="filter_text = 'all'">
								<v-list-item-title>all</v-list-item-title>
								<v-list-item-action>
									<v-badge color="grey" :content="all_count" offset-y="10"></v-badge>
								</v-list-item-action>
							</v-list-item>
							<v-divider class="my-0"></v-divider>
							<v-list-item class="py-1" @click="filter_text = 'quotation'">
								<v-list-item-title>quotation</v-list-item-title>
								<v-list-item-action>
									<v-badge color="teal" :content="all_quotation_count" offset-y="10"></v-badge>
								</v-list-item-action>
							</v-list-item>
							<v-divider class="my-0"></v-divider>
							<v-list-item class="py-1" @click="filter_text = 'purchase-order'">
								<v-list-item-title>purchase-order</v-list-item-title>
								<v-list-item-action>
									<v-badge color="blue" :content="all_po_count" offset-y="10"></v-badge>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-4 white--text" tile depressed @click="createNewCondition()">
					<v-icon left>mdi-plus</v-icon>
					Create
				</v-btn>
			</v-layout>
			<div class="px-6 py-4">
				<v-row class="py-0">
					<v-col md="12" class="py-0">
						<v-expansion-panels
							focusable
							flat
							accordion
							tile
							v-model="expansen_panel"
							multiple
							class="mt-2 transparent-panels overflow-y"
						>
							<v-expansion-panel v-for="(row, index) in terms_and_conditions" :key="index">
								<v-expansion-panel-header>
									<v-layout class="">
										<span class="text-initial"> {{ index + 1 }} - &nbsp; </span>
										<span class="text-initial">
											{{ row.title }}
										</span>
										<v-spacer></v-spacer>
										<v-chip label small :color="`${getColor(row.module)} white--text`" class="mr-15">
											{{ row.module }}
										</v-chip>
										<v-icon @click.stop.prevent="editCondition(row)" :color="`blue darken-4`" class="mr-4"
											>mdi-pencil-circle-outline</v-icon
										>
										<v-icon @click.stop.prevent="remvoeCondition(row)" :color="`red lighten-1`" class="mr-4"
											>mdi-delete-outline</v-icon
										>
									</v-layout>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div class="pl-7">
										<ShowTextAreaValue
											:object="row"
											object-key="terms_and_conditions"
											label="terms and conditions description"
										>
										</ShowTextAreaValue>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>
				</v-row>
			</div>
		</div>
		<TermsConditionCreate
			v-if="createDialog"
			:drawer="createDialog"
			:drawer-type="drawerType"
			:default-data="editData"
			v-on:close="createDialog = false"
		></TermsConditionCreate>
		<DeleteTemplate
			type="Terms and Condition"
			:delete-text="deleteText"
			v-on:close="deleteDialog = false"
			v-on:success="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TermsConditionCreate from "@/view/components/TermsConditionCreate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import { filter } from "lodash";
export default {
	name: "TermsAndConditions",
	data() {
		return {
			createDialog: false,
			deleteDialog: false,
			editData: null,
			deleteData: null,
			deleteText: null,
			deleteURL: null,
			drawerType: "create",
			filter_text: "all",
			termsAndConditionList: [],
			pageLoading: null,
			expansen_panel: [0],
		};
	},
	components: {
		TermsConditionCreate,
		DeleteTemplate,
		ShowTextAreaValue,
	},
	methods: {
		createNewCondition() {
			this.drawerType = "create";
			this.createDialog = true;
		},
		editCondition(row) {
			this.drawerType = "update";
			this.editData = row;
			this.createDialog = true;
		},
		remvoeCondition(row) {
			this.deleteData = row;
			this.deleteURL = `termconditions/${row.uuid}`;
			this.deleteText = row.title;
			this.deleteDialog = true;
		},
		getColor(module) {
			switch (module) {
				case "quotation":
					return "teal";
				case "purchase-order":
					return "blue";
				default:
					return "blue";
			}
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		terms_and_conditions() {
			let _data = this.localDB("terms_and_conditions", []);
			if (this.filter_text == "all") {
				return _data;
			}
			if (this.filter_text == "purchase-order") {
				return filter(_data, (row) => row.module == "purchase-order");
			}
			if (this.filter_text == "quotation") {
				return filter(_data, (row) => row.module == "quotation");
			}
			return _data;
		},
		all_count() {
			let _data = this.localDB("terms_and_conditions", []);
			return _data.length;
		},
		all_quotation_count() {
			let _data = this.localDB("terms_and_conditions", []);
			const _quotation = filter(_data, (row) => row.module == "quotation");
			return _quotation.length;
		},
		all_po_count() {
			let _data = this.localDB("terms_and_conditions", []);
			const _po = filter(_data, (row) => row.module == "purchase-order");
			return _po.length;
		},
	},
	mounted() {
		this.termsAndConditionList = this.localDB("terms_and_conditions", []);
	},
};
</script>
